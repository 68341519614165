export const flagEmoji = (isoCode) => {
  const codePoints = isoCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const regions = [
  {
    value: "eastMidlands",
    name: "East Midlands",
  },
  {
    value: "eastOfEngland",
    name: "East of England",
  },
  {
    value: "westMidlands",
    name: "West Midlands",
  },
  {
    value: "london",
    name: "London",
  },
  {
    value: "northEast",
    name: "North East",
  },
  {
    value: "northWest",
    name: "North West",
  },
  {
    value: "southEast",
    name: "South East",
  },
  {
    value: "southWest",
    name: "South West",
  },
  {
    value: "yorkshireAndTheHumber",
    name: "Yorkshire and the Humber",
  },
  {
    value: "northernIreland",
    name: "Northern Ireland",
  },
  {
    value: "scotland",
    name: "Scotland",
  },
  {
    value: "wales",
    name: "Wales",
  },
];

export const countries = [
  {
    name: "Afghanistan",
    iso2: "AF",
    iso3: "AFG",
    numeric: 4,
  },
  {
    name: "Albania",
    iso2: "AL",
    iso3: "ALB",
    numeric: 8,
  },
  {
    name: "Algeria",
    iso2: "DZ",
    iso3: "DZA",
    numeric: 12,
  },
  {
    name: "American Samoa",
    iso2: "AS",
    iso3: "ASM",
    numeric: 16,
  },
  {
    name: "Andorra",
    iso2: "AD",
    iso3: "AND",
    numeric: 20,
  },
  {
    name: "Angola",
    iso2: "AO",
    iso3: "AGO",
    numeric: 24,
  },
  {
    name: "Anguilla",
    iso2: "AI",
    iso3: "AIA",
    numeric: 660,
  },
  {
    name: "Antarctica",
    iso2: "AQ",
    iso3: "ATA",
    numeric: 10,
  },
  {
    name: "Antigua and Barbuda",
    iso2: "AG",
    iso3: "ATG",
    numeric: 28,
  },
  {
    name: "Argentina",
    iso2: "AR",
    iso3: "ARG",
    numeric: 32,
  },
  {
    name: "Armenia",
    iso2: "AM",
    iso3: "ARM",
    numeric: 51,
  },
  {
    name: "Aruba",
    iso2: "AW",
    iso3: "ABW",
    numeric: 533,
  },
  {
    name: "Australia",
    iso2: "AU",
    iso3: "AUS",
    numeric: 36,
  },
  {
    name: "Austria",
    iso2: "AT",
    iso3: "AUT",
    numeric: 40,
  },
  {
    name: "Azerbaijan",
    iso2: "AZ",
    iso3: "AZE",
    numeric: 31,
  },
  {
    name: "Bahamas (the)",
    iso2: "BS",
    iso3: "BHS",
    numeric: 44,
  },
  {
    name: "Bahrain",
    iso2: "BH",
    iso3: "BHR",
    numeric: 48,
  },
  {
    name: "Bangladesh",
    iso2: "BD",
    iso3: "BGD",
    numeric: 50,
  },
  {
    name: "Barbados",
    iso2: "BB",
    iso3: "BRB",
    numeric: 52,
  },
  {
    name: "Belarus",
    iso2: "BY",
    iso3: "BLR",
    numeric: 112,
  },
  {
    name: "Belgium",
    iso2: "BE",
    iso3: "BEL",
    numeric: 56,
  },
  {
    name: "Belize",
    iso2: "BZ",
    iso3: "BLZ",
    numeric: 84,
  },
  {
    name: "Benin",
    iso2: "BJ",
    iso3: "BEN",
    numeric: 204,
  },
  {
    name: "Bermuda",
    iso2: "BM",
    iso3: "BMU",
    numeric: 60,
  },
  {
    name: "Bhutan",
    iso2: "BT",
    iso3: "BTN",
    numeric: 64,
  },
  {
    name: "Bolivia (Plurinational State of)",
    iso2: "BO",
    iso3: "BOL",
    numeric: 68,
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    iso2: "BQ",
    iso3: "BES",
    numeric: 535,
  },
  {
    name: "Bosnia and Herzegovina",
    iso2: "BA",
    iso3: "BIH",
    numeric: 70,
  },
  {
    name: "Botswana",
    iso2: "BW",
    iso3: "BWA",
    numeric: 72,
  },
  {
    name: "Bouvet Island",
    iso2: "BV",
    iso3: "BVT",
    numeric: 74,
  },
  {
    name: "Brazil",
    iso2: "BR",
    iso3: "BRA",
    numeric: 76,
  },
  {
    name: "British Indian Ocean Territory (the)",
    iso2: "IO",
    iso3: "IOT",
    numeric: 86,
  },
  {
    name: "Brunei Darussalam",
    iso2: "BN",
    iso3: "BRN",
    numeric: 96,
  },
  {
    name: "Bulgaria",
    iso2: "BG",
    iso3: "BGR",
    numeric: 100,
  },
  {
    name: "Burkina Faso",
    iso2: "BF",
    iso3: "BFA",
    numeric: 854,
  },
  {
    name: "Burundi",
    iso2: "BI",
    iso3: "BDI",
    numeric: 108,
  },
  {
    name: "Cabo Verde",
    iso2: "CV",
    iso3: "CPV",
    numeric: 132,
  },
  {
    name: "Cambodia",
    iso2: "KH",
    iso3: "KHM",
    numeric: 116,
  },
  {
    name: "Cameroon",
    iso2: "CM",
    iso3: "CMR",
    numeric: 120,
  },
  {
    name: "Canada",
    iso2: "CA",
    iso3: "CAN",
    numeric: 124,
  },
  {
    name: "Cayman Islands (the)",
    iso2: "KY",
    iso3: "CYM",
    numeric: 136,
  },
  {
    name: "Central African Republic (the)",
    iso2: "CF",
    iso3: "CAF",
    numeric: 140,
  },
  {
    name: "Chad",
    iso2: "TD",
    iso3: "TCD",
    numeric: 148,
  },
  {
    name: "Chile",
    iso2: "CL",
    iso3: "CHL",
    numeric: 152,
  },
  {
    name: "China",
    iso2: "CN",
    iso3: "CHN",
    numeric: 156,
  },
  {
    name: "Christmas Island",
    iso2: "CX",
    iso3: "CXR",
    numeric: 162,
  },
  {
    name: "Cocos (Keeling) Islands (the)",
    iso2: "CC",
    iso3: "CCK",
    numeric: 166,
  },
  {
    name: "Colombia",
    iso2: "CO",
    iso3: "COL",
    numeric: 170,
  },
  {
    name: "Comoros (the)",
    iso2: "KM",
    iso3: "COM",
    numeric: 174,
  },
  {
    name: "Congo (the Democratic Republic of the)",
    iso2: "CD",
    iso3: "COD",
    numeric: 180,
  },
  {
    name: "Congo (the)",
    iso2: "CG",
    iso3: "COG",
    numeric: 178,
  },
  {
    name: "Cook Islands (the)",
    iso2: "CK",
    iso3: "COK",
    numeric: 184,
  },
  {
    name: "Costa Rica",
    iso2: "CR",
    iso3: "CRI",
    numeric: 188,
  },
  {
    name: "Croatia",
    iso2: "HR",
    iso3: "HRV",
    numeric: 191,
  },
  {
    name: "Cuba",
    iso2: "CU",
    iso3: "CUB",
    numeric: 192,
  },
  {
    name: "Curaçao",
    iso2: "CW",
    iso3: "CUW",
    numeric: 531,
  },
  {
    name: "Cyprus",
    iso2: "CY",
    iso3: "CYP",
    numeric: 196,
  },
  {
    name: "Czechia",
    iso2: "CZ",
    iso3: "CZE",
    numeric: 203,
  },
  {
    name: "Côte d'Ivoire",
    iso2: "CI",
    iso3: "CIV",
    numeric: 384,
  },
  {
    name: "Denmark",
    iso2: "DK",
    iso3: "DNK",
    numeric: 208,
  },
  {
    name: "Djibouti",
    iso2: "DJ",
    iso3: "DJI",
    numeric: 262,
  },
  {
    name: "Dominica",
    iso2: "DM",
    iso3: "DMA",
    numeric: 212,
  },
  {
    name: "Dominican Republic (the)",
    iso2: "DO",
    iso3: "DOM",
    numeric: 214,
  },
  {
    name: "Ecuador",
    iso2: "EC",
    iso3: "ECU",
    numeric: 218,
  },
  {
    name: "Egypt",
    iso2: "EG",
    iso3: "EGY",
    numeric: 818,
  },
  {
    name: "El Salvador",
    iso2: "SV",
    iso3: "SLV",
    numeric: 222,
  },
  {
    name: "Equatorial Guinea",
    iso2: "GQ",
    iso3: "GNQ",
    numeric: 226,
  },
  {
    name: "Eritrea",
    iso2: "ER",
    iso3: "ERI",
    numeric: 232,
  },
  {
    name: "Estonia",
    iso2: "EE",
    iso3: "EST",
    numeric: 233,
  },
  {
    name: "Eswatini",
    iso2: "SZ",
    iso3: "SWZ",
    numeric: 748,
  },
  {
    name: "Ethiopia",
    iso2: "ET",
    iso3: "ETH",
    numeric: 231,
  },
  {
    name: "Falkland Islands (the) [Malvinas]",
    iso2: "FK",
    iso3: "FLK",
    numeric: 238,
  },
  {
    name: "Faroe Islands (the)",
    iso2: "FO",
    iso3: "FRO",
    numeric: 234,
  },
  {
    name: "Fiji",
    iso2: "FJ",
    iso3: "FJI",
    numeric: 242,
  },
  {
    name: "Finland",
    iso2: "FI",
    iso3: "FIN",
    numeric: 246,
  },
  {
    name: "France",
    iso2: "FR",
    iso3: "FRA",
    numeric: 250,
  },
  {
    name: "French Guiana",
    iso2: "GF",
    iso3: "GUF",
    numeric: 254,
  },
  {
    name: "French Polynesia",
    iso2: "PF",
    iso3: "PYF",
    numeric: 258,
  },
  {
    name: "French Southern Territories (the)",
    iso2: "TF",
    iso3: "ATF",
    numeric: 260,
  },
  {
    name: "Gabon",
    iso2: "GA",
    iso3: "GAB",
    numeric: 266,
  },
  {
    name: "Gambia (the)",
    iso2: "GM",
    iso3: "GMB",
    numeric: 270,
  },
  {
    name: "Georgia",
    iso2: "GE",
    iso3: "GEO",
    numeric: 268,
  },
  {
    name: "Germany",
    iso2: "DE",
    iso3: "DEU",
    numeric: 276,
  },
  {
    name: "Ghana",
    iso2: "GH",
    iso3: "GHA",
    numeric: 288,
  },
  {
    name: "Gibraltar",
    iso2: "GI",
    iso3: "GIB",
    numeric: 292,
  },
  {
    name: "Greece",
    iso2: "GR",
    iso3: "GRC",
    numeric: 300,
  },
  {
    name: "Greenland",
    iso2: "GL",
    iso3: "GRL",
    numeric: 304,
  },
  {
    name: "Grenada",
    iso2: "GD",
    iso3: "GRD",
    numeric: 308,
  },
  {
    name: "Guadeloupe",
    iso2: "GP",
    iso3: "GLP",
    numeric: 312,
  },
  {
    name: "Guam",
    iso2: "GU",
    iso3: "GUM",
    numeric: 316,
  },
  {
    name: "Guatemala",
    iso2: "GT",
    iso3: "GTM",
    numeric: 320,
  },
  {
    name: "Guernsey",
    iso2: "GG",
    iso3: "GGY",
    numeric: 831,
  },
  {
    name: "Guinea",
    iso2: "GN",
    iso3: "GIN",
    numeric: 324,
  },
  {
    name: "Guinea-Bissau",
    iso2: "GW",
    iso3: "GNB",
    numeric: 624,
  },
  {
    name: "Guyana",
    iso2: "GY",
    iso3: "GUY",
    numeric: 328,
  },
  {
    name: "Haiti",
    iso2: "HT",
    iso3: "HTI",
    numeric: 332,
  },
  {
    name: "Heard Island and McDonald Islands",
    iso2: "HM",
    iso3: "HMD",
    numeric: 334,
  },
  {
    name: "Holy See (the)",
    iso2: "VA",
    iso3: "VAT",
    numeric: 336,
  },
  {
    name: "Honduras",
    iso2: "HN",
    iso3: "HND",
    numeric: 340,
  },
  {
    name: "Hong Kong",
    iso2: "HK",
    iso3: "HKG",
    numeric: 344,
  },
  {
    name: "Hungary",
    iso2: "HU",
    iso3: "HUN",
    numeric: 348,
  },
  {
    name: "Iceland",
    iso2: "IS",
    iso3: "ISL",
    numeric: 352,
  },
  {
    name: "India",
    iso2: "IN",
    iso3: "IND",
    numeric: 356,
  },
  {
    name: "Indonesia",
    iso2: "ID",
    iso3: "IDN",
    numeric: 360,
  },
  {
    name: "Iran (Islamic Republic of)",
    iso2: "IR",
    iso3: "IRN",
    numeric: 364,
  },
  {
    name: "Iraq",
    iso2: "IQ",
    iso3: "IRQ",
    numeric: 368,
  },
  {
    name: "Ireland",
    iso2: "IE",
    iso3: "IRL",
    numeric: 372,
  },
  {
    name: "Isle of Man",
    iso2: "IM",
    iso3: "IMN",
    numeric: 833,
  },
  {
    name: "Israel",
    iso2: "IL",
    iso3: "ISR",
    numeric: 376,
  },
  {
    name: "Italy",
    iso2: "IT",
    iso3: "ITA",
    numeric: 380,
  },
  {
    name: "Jamaica",
    iso2: "JM",
    iso3: "JAM",
    numeric: 388,
  },
  {
    name: "Japan",
    iso2: "JP",
    iso3: "JPN",
    numeric: 392,
  },
  {
    name: "Jersey",
    iso2: "JE",
    iso3: "JEY",
    numeric: 832,
  },
  {
    name: "Jordan",
    iso2: "JO",
    iso3: "JOR",
    numeric: 400,
  },
  {
    name: "Kazakhstan",
    iso2: "KZ",
    iso3: "KAZ",
    numeric: 398,
  },
  {
    name: "Kenya",
    iso2: "KE",
    iso3: "KEN",
    numeric: 404,
  },
  {
    name: "Kiribati",
    iso2: "KI",
    iso3: "KIR",
    numeric: 296,
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    iso2: "KP",
    iso3: "PRK",
    numeric: 408,
  },
  {
    name: "Korea (the Republic of)",
    iso2: "KR",
    iso3: "KOR",
    numeric: 410,
  },
  {
    name: "Kuwait",
    iso2: "KW",
    iso3: "KWT",
    numeric: 414,
  },
  {
    name: "Kyrgyzstan",
    iso2: "KG",
    iso3: "KGZ",
    numeric: 417,
  },
  {
    name: "Lao People's Democratic Republic (the)",
    iso2: "LA",
    iso3: "LAO",
    numeric: 418,
  },
  {
    name: "Latvia",
    iso2: "LV",
    iso3: "LVA",
    numeric: 428,
  },
  {
    name: "Lebanon",
    iso2: "LB",
    iso3: "LBN",
    numeric: 422,
  },
  {
    name: "Lesotho",
    iso2: "LS",
    iso3: "LSO",
    numeric: 426,
  },
  {
    name: "Liberia",
    iso2: "LR",
    iso3: "LBR",
    numeric: 430,
  },
  {
    name: "Libya",
    iso2: "LY",
    iso3: "LBY",
    numeric: 434,
  },
  {
    name: "Liechtenstein",
    iso2: "LI",
    iso3: "LIE",
    numeric: 438,
  },
  {
    name: "Lithuania",
    iso2: "LT",
    iso3: "LTU",
    numeric: 440,
  },
  {
    name: "Luxembourg",
    iso2: "LU",
    iso3: "LUX",
    numeric: 442,
  },
  {
    name: "Macao",
    iso2: "MO",
    iso3: "MAC",
    numeric: 446,
  },
  {
    name: "Madagascar",
    iso2: "MG",
    iso3: "MDG",
    numeric: 450,
  },
  {
    name: "Malawi",
    iso2: "MW",
    iso3: "MWI",
    numeric: 454,
  },
  {
    name: "Malaysia",
    iso2: "MY",
    iso3: "MYS",
    numeric: 458,
  },
  {
    name: "Maldives",
    iso2: "MV",
    iso3: "MDV",
    numeric: 462,
  },
  {
    name: "Mali",
    iso2: "ML",
    iso3: "MLI",
    numeric: 466,
  },
  {
    name: "Malta",
    iso2: "MT",
    iso3: "MLT",
    numeric: 470,
  },
  {
    name: "Marshall Islands (the)",
    iso2: "MH",
    iso3: "MHL",
    numeric: 584,
  },
  {
    name: "Martinique",
    iso2: "MQ",
    iso3: "MTQ",
    numeric: 474,
  },
  {
    name: "Mauritania",
    iso2: "MR",
    iso3: "MRT",
    numeric: 478,
  },
  {
    name: "Mauritius",
    iso2: "MU",
    iso3: "MUS",
    numeric: 480,
  },
  {
    name: "Mayotte",
    iso2: "YT",
    iso3: "MYT",
    numeric: 175,
  },
  {
    name: "Mexico",
    iso2: "MX",
    iso3: "MEX",
    numeric: 484,
  },
  {
    name: "Micronesia (Federated States of)",
    iso2: "FM",
    iso3: "FSM",
    numeric: 583,
  },
  {
    name: "Moldova (the Republic of)",
    iso2: "MD",
    iso3: "MDA",
    numeric: 498,
  },
  {
    name: "Monaco",
    iso2: "MC",
    iso3: "MCO",
    numeric: 492,
  },
  {
    name: "Mongolia",
    iso2: "MN",
    iso3: "MNG",
    numeric: 496,
  },
  {
    name: "Montenegro",
    iso2: "ME",
    iso3: "MNE",
    numeric: 499,
  },
  {
    name: "Montserrat",
    iso2: "MS",
    iso3: "MSR",
    numeric: 500,
  },
  {
    name: "Morocco",
    iso2: "MA",
    iso3: "MAR",
    numeric: 504,
  },
  {
    name: "Mozambique",
    iso2: "MZ",
    iso3: "MOZ",
    numeric: 508,
  },
  {
    name: "Myanmar",
    iso2: "MM",
    iso3: "MMR",
    numeric: 104,
  },
  {
    name: "Namibia",
    iso2: "NA",
    iso3: "NAM",
    numeric: 516,
  },
  {
    name: "Nauru",
    iso2: "NR",
    iso3: "NRU",
    numeric: 520,
  },
  {
    name: "Nepal",
    iso2: "NP",
    iso3: "NPL",
    numeric: 524,
  },
  {
    name: "Netherlands (the)",
    iso2: "NL",
    iso3: "NLD",
    numeric: 528,
  },
  {
    name: "New Caledonia",
    iso2: "NC",
    iso3: "NCL",
    numeric: 540,
  },
  {
    name: "New Zealand",
    iso2: "NZ",
    iso3: "NZL",
    numeric: 554,
  },
  {
    name: "Nicaragua",
    iso2: "NI",
    iso3: "NIC",
    numeric: 558,
  },
  {
    name: "Niger (the)",
    iso2: "NE",
    iso3: "NER",
    numeric: 562,
  },
  {
    name: "Nigeria",
    iso2: "NG",
    iso3: "NGA",
    numeric: 566,
  },
  {
    name: "Niue",
    iso2: "NU",
    iso3: "NIU",
    numeric: 570,
  },
  {
    name: "Norfolk Island",
    iso2: "NF",
    iso3: "NFK",
    numeric: 574,
  },
  {
    name: "North Macedonia",
    iso2: "MK",
    iso3: "MKD",
    numeric: 807,
  },
  {
    name: "Northern Mariana Islands (the)",
    iso2: "MP",
    iso3: "MNP",
    numeric: 580,
  },
  {
    name: "Norway",
    iso2: "NO",
    iso3: "NOR",
    numeric: 578,
  },
  {
    name: "Oman",
    iso2: "OM",
    iso3: "OMN",
    numeric: 512,
  },
  {
    name: "Pakistan",
    iso2: "PK",
    iso3: "PAK",
    numeric: 586,
  },
  {
    name: "Palau",
    iso2: "PW",
    iso3: "PLW",
    numeric: 585,
  },
  {
    name: "Palestine, State of",
    iso2: "PS",
    iso3: "PSE",
    numeric: 275,
  },
  {
    name: "Panama",
    iso2: "PA",
    iso3: "PAN",
    numeric: 591,
  },
  {
    name: "Papua New Guinea",
    iso2: "PG",
    iso3: "PNG",
    numeric: 598,
  },
  {
    name: "Paraguay",
    iso2: "PY",
    iso3: "PRY",
    numeric: 600,
  },
  {
    name: "Peru",
    iso2: "PE",
    iso3: "PER",
    numeric: 604,
  },
  {
    name: "Philippines (the)",
    iso2: "PH",
    iso3: "PHL",
    numeric: 608,
  },
  {
    name: "Pitcairn",
    iso2: "PN",
    iso3: "PCN",
    numeric: 612,
  },
  {
    name: "Poland",
    iso2: "PL",
    iso3: "POL",
    numeric: 616,
  },
  {
    name: "Portugal",
    iso2: "PT",
    iso3: "PRT",
    numeric: 620,
  },
  {
    name: "Puerto Rico",
    iso2: "PR",
    iso3: "PRI",
    numeric: 630,
  },
  {
    name: "Qatar",
    iso2: "QA",
    iso3: "QAT",
    numeric: 634,
  },
  {
    name: "Romania",
    iso2: "RO",
    iso3: "ROU",
    numeric: 642,
  },
  {
    name: "Russian Federation (the)",
    iso2: "RU",
    iso3: "RUS",
    numeric: 643,
  },
  {
    name: "Rwanda",
    iso2: "RW",
    iso3: "RWA",
    numeric: 646,
  },
  {
    name: "Réunion",
    iso2: "RE",
    iso3: "REU",
    numeric: 638,
  },
  {
    name: "Saint Barthélemy",
    iso2: "BL",
    iso3: "BLM",
    numeric: 652,
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    iso2: "SH",
    iso3: "SHN",
    numeric: 654,
  },
  {
    name: "Saint Kitts and Nevis",
    iso2: "KN",
    iso3: "KNA",
    numeric: 659,
  },
  {
    name: "Saint Lucia",
    iso2: "LC",
    iso3: "LCA",
    numeric: 662,
  },
  {
    name: "Saint Martin (French part)",
    iso2: "MF",
    iso3: "MAF",
    numeric: 663,
  },
  {
    name: "Saint Pierre and Miquelon",
    iso2: "PM",
    iso3: "SPM",
    numeric: 666,
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso2: "VC",
    iso3: "VCT",
    numeric: 670,
  },
  {
    name: "Samoa",
    iso2: "WS",
    iso3: "WSM",
    numeric: 882,
  },
  {
    name: "San Marino",
    iso2: "SM",
    iso3: "SMR",
    numeric: 674,
  },
  {
    name: "Sao Tome and Principe",
    iso2: "ST",
    iso3: "STP",
    numeric: 678,
  },
  {
    name: "Saudi Arabia",
    iso2: "SA",
    iso3: "SAU",
    numeric: 682,
  },
  {
    name: "Senegal",
    iso2: "SN",
    iso3: "SEN",
    numeric: 686,
  },
  {
    name: "Serbia",
    iso2: "RS",
    iso3: "SRB",
    numeric: 688,
  },
  {
    name: "Seychelles",
    iso2: "SC",
    iso3: "SYC",
    numeric: 690,
  },
  {
    name: "Sierra Leone",
    iso2: "SL",
    iso3: "SLE",
    numeric: 694,
  },
  {
    name: "Singapore",
    iso2: "SG",
    iso3: "SGP",
    numeric: 702,
  },
  {
    name: "Sint Maarten (Dutch part)",
    iso2: "SX",
    iso3: "SXM",
    numeric: 534,
  },
  {
    name: "Slovakia",
    iso2: "SK",
    iso3: "SVK",
    numeric: 703,
  },
  {
    name: "Slovenia",
    iso2: "SI",
    iso3: "SVN",
    numeric: 705,
  },
  {
    name: "Solomon Islands",
    iso2: "SB",
    iso3: "SLB",
    numeric: 90,
  },
  {
    name: "Somalia",
    iso2: "SO",
    iso3: "SOM",
    numeric: 706,
  },
  {
    name: "South Africa",
    iso2: "ZA",
    iso3: "ZAF",
    numeric: 710,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    iso2: "GS",
    iso3: "SGS",
    numeric: 239,
  },
  {
    name: "South Sudan",
    iso2: "SS",
    iso3: "SSD",
    numeric: 728,
  },
  {
    name: "Spain",
    iso2: "ES",
    iso3: "ESP",
    numeric: 724,
  },
  {
    name: "Sri Lanka",
    iso2: "LK",
    iso3: "LKA",
    numeric: 144,
  },
  {
    name: "Sudan (the)",
    iso2: "SD",
    iso3: "SDN",
    numeric: 729,
  },
  {
    name: "Suriname",
    iso2: "SR",
    iso3: "SUR",
    numeric: 740,
  },
  {
    name: "Svalbard and Jan Mayen",
    iso2: "SJ",
    iso3: "SJM",
    numeric: 744,
  },
  {
    name: "Sweden",
    iso2: "SE",
    iso3: "SWE",
    numeric: 752,
  },
  {
    name: "Switzerland",
    iso2: "CH",
    iso3: "CHE",
    numeric: 756,
  },
  {
    name: "Syrian Arab Republic (the)",
    iso2: "SY",
    iso3: "SYR",
    numeric: 760,
  },
  {
    name: "Taiwan (Province of China)",
    iso2: "TW",
    iso3: "TWN",
    numeric: 158,
  },
  {
    name: "Tajikistan",
    iso2: "TJ",
    iso3: "TJK",
    numeric: 762,
  },
  {
    name: "Tanzania, the United Republic of",
    iso2: "TZ",
    iso3: "TZA",
    numeric: 834,
  },
  {
    name: "Thailand",
    iso2: "TH",
    iso3: "THA",
    numeric: 764,
  },
  {
    name: "Timor-Leste",
    iso2: "TL",
    iso3: "TLS",
    numeric: 626,
  },
  {
    name: "Togo",
    iso2: "TG",
    iso3: "TGO",
    numeric: 768,
  },
  {
    name: "Tokelau",
    iso2: "TK",
    iso3: "TKL",
    numeric: 772,
  },
  {
    name: "Tonga",
    iso2: "TO",
    iso3: "TON",
    numeric: 776,
  },
  {
    name: "Trinidad and Tobago",
    iso2: "TT",
    iso3: "TTO",
    numeric: 780,
  },
  {
    name: "Tunisia",
    iso2: "TN",
    iso3: "TUN",
    numeric: 788,
  },
  {
    name: "Turkey",
    iso2: "TR",
    iso3: "TUR",
    numeric: 792,
  },
  {
    name: "Turkmenistan",
    iso2: "TM",
    iso3: "TKM",
    numeric: 795,
  },
  {
    name: "Turks and Caicos Islands (the)",
    iso2: "TC",
    iso3: "TCA",
    numeric: 796,
  },
  {
    name: "Tuvalu",
    iso2: "TV",
    iso3: "TUV",
    numeric: 798,
  },
  {
    name: "Uganda",
    iso2: "UG",
    iso3: "UGA",
    numeric: 800,
  },
  {
    name: "Ukraine",
    iso2: "UA",
    iso3: "UKR",
    numeric: 804,
  },
  {
    name: "United Arab Emirates (the)",
    iso2: "AE",
    iso3: "ARE",
    numeric: 784,
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    iso2: "GB",
    iso3: "GBR",
    numeric: 826,
  },
  {
    name: "United States Minor Outlying Islands (the)",
    iso2: "UM",
    iso3: "UMI",
    numeric: 581,
  },
  {
    name: "United States of America (the)",
    iso2: "US",
    iso3: "USA",
    numeric: 840,
  },
  {
    name: "Uruguay",
    iso2: "UY",
    iso3: "URY",
    numeric: 858,
  },
  {
    name: "Uzbekistan",
    iso2: "UZ",
    iso3: "UZB",
    numeric: 860,
  },
  {
    name: "Vanuatu",
    iso2: "VU",
    iso3: "VUT",
    numeric: 548,
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    iso2: "VE",
    iso3: "VEN",
    numeric: 862,
  },
  {
    name: "Viet Nam",
    iso2: "VN",
    iso3: "VNM",
    numeric: 704,
  },
  {
    name: "Virgin Islands (British)",
    iso2: "VG",
    iso3: "VGB",
    numeric: 92,
  },
  {
    name: "Virgin Islands (U.S.)",
    iso2: "VI",
    iso3: "VIR",
    numeric: 850,
  },
  {
    name: "Wallis and Futuna",
    iso2: "WF",
    iso3: "WLF",
    numeric: 876,
  },
  {
    name: "Western Sahara*",
    iso2: "EH",
    iso3: "ESH",
    numeric: 732,
  },
  {
    name: "Yemen",
    iso2: "YE",
    iso3: "YEM",
    numeric: 887,
  },
  {
    name: "Zambia",
    iso2: "ZM",
    iso3: "ZMB",
    numeric: 894,
  },
  {
    name: "Zimbabwe",
    iso2: "ZW",
    iso3: "ZWE",
    numeric: 716,
  },
  {
    name: "Åland Islands",
    iso2: "AX",
    iso3: "ALA",
    numeric: 248,
  },
];
